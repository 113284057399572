<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Import Beat" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <ImportBeat @submit="getDataTable()" ref="import-beat"/>
                </Fieldset>

                <Fieldset legend="Import Beat Outlet" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <ImportBeatOutlet @submit="getDataTable()" ref="import-beat-outlet"/>
                </Fieldset>

                <Fieldset legend="Add New" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <Create @submit="getDataTable()" ref="create"/>
                </Fieldset>
                
                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3"  v-if="permission.includes('read')">
                    <Filter @submit="getDataTable()" ref="filter"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" v-if="permission.includes('read')">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <SplitButton :loading="loadingExcel || loadingCsv" :disabled="loadingExcel || loadingCsv" label="Export Beat" icon="pi pi-file-excel" :model="itemsExportBeat" class="p-button-success mr-2 my-1" v-if="permission.includes('download')"></SplitButton>
                                <SplitButton :loading="loadingBeatOutletExcel || loadingBeatOutletCsv" :disabled="loadingBeatOutletExcel || loadingBeatOutletCsv" label="Export Beat + Outlet" icon="pi pi-file-excel" :model="itemsExportBeatOutlet" class="p-button-success mr-2 my-1" v-if="permission.includes('download')"></SplitButton>

                                <span v-if="permission.includes('download') "> 
                                    <Button :loading="loadingBeatOutletExportCheckbox" label="Export" icon="pi pi-file-excel" class=" mr-2 my-1 inline-block" @click="exportBeatOutletExportCheckbox('xlsx')" v-if="itemBeatOutletExport.length > 0" />
                                </span>
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column :style="{width:'20px'}" >
                        <template #body="slotProps">
                            <Checkbox name="beat" :value="slotProps.data.beat_id" v-model="itemBeatOutletExport" v-if="permission.includes('download') "/>
                        </template>
                    </Column>
                    <Column field="number" header="#" :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="beat_code" header="Beat Code">
                        <template #body="slotProps">
                            {{slotProps.data.beat_code}}
                        </template>
                    </Column>
                    <Column field="beat_name" header="Beat">
                        <template #body="slotProps">
                            {{slotProps.data.beat_name}}
                        </template>
                    </Column>
                    <Column field="db_code" header="Distributor Code">
                        <template #body="slotProps">
                            {{slotProps.data.db_code}}
                        </template>
                    </Column>
                    <Column field="db_name" header="Distributor Name">
                        <template #body="slotProps">
                            {{slotProps.data.db_name}}
                        </template>
                    </Column>
                    <Column field="city_code" header="City Code">
                        <template #body="slotProps">
                            {{slotProps.data.city_code}}
                        </template>
                    </Column>
                    <Column field="territory_name" header="Territory">
                        <template #body="slotProps">
                            {{slotProps.data.territory_name}}
                        </template>
                    </Column>
                    <Column field="city_name" header="City">
                        <template #body="slotProps">
                            {{slotProps.data.city_name}}
                        </template>
                    </Column>
                    <Column field="beat_status" header="Beat Status">
                        <template #body="slotProps">
                            <Button icon="pi pi-check" class="p-button-rounded" v-if="slotProps.data.beat_status == 'y'"/>
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-else/>
                        </template>
                    </Column>
					<Column header="Action" headerStyle="min-width:26rem;">
						<template #body="slotProps">
                            <span class="p-buttonset">
                                <Button label="Edit" icon="pi pi-pencil" @click="editItem(slotProps.data)" v-if="permission.includes('update')"/>
                                <Button label="Outlet" icon="pi pi-external-link" @click="detailItem(slotProps.data.beat_id,'outlet')" v-if="permission.includes('read')"/>
                                <Button label="User" icon="pi pi-external-link" @click="detailItem(slotProps.data.beat_id,'user')" v-if="permission.includes('read')"/>
                            </span>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]"  v-if="permission.includes('read')">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

                <Edit @submit="getDataTable()" :item="item" ref="edit"/>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Create from '../../components/master/beat/Create.vue';
import ImportBeatOutlet from '../../components/master/beat/ImportBeatOutlet.vue';
import ImportBeat from '../../components/master/beat/ImportBeat.vue';
import Filter from '../../components/master/beat/Filter.vue';
import Edit from '../../components/master/beat/Edit.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'Create': Create,
		'ImportBeatOutlet': ImportBeatOutlet,
		'ImportBeat': ImportBeat,
		'Filter': Filter,
		'Edit': Edit,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            loadingBeatOutletExcel: false,
            loadingBeatOutletCsv: false,
            loadingBeatOutletExportCheckbox: false,

            // edit
            item: {},

            itemsExportBeat: [
				{
					label: 'Excel',
					icon: 'pi pi-download',
					command: () => {
						this.exportExcelCSV('xlsx');
					}
				},
				{
					label: 'CSV',
					icon: 'pi pi-download',
					command: () => {
						this.exportExcelCSV('csv');
					}
				},
			],
            itemsExportBeatOutlet: [
				{
					label: 'Excel',
					icon: 'pi pi-download',
					command: () => {
						this.exportBeatOutletExcelCSV('xlsx');
					}
				},
				{
					label: 'CSV',
					icon: 'pi pi-download',
					command: () => {
						this.exportBeatOutletExcelCSV('csv');
					}
				},
			],
            itemBeatOutletExport: [],
            
            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors']),

		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DETAIL
		detailItem(id, to) {
            if(to == 'outlet'){
                this.$router.replace({ name: "beat-outlet", params: {id:id} })
            }else if(to == 'user'){
                this.$router.replace({ name: "beat-user", params: {id:id} })
            }else{
                alert('sorry page doesnt exist');
            }
		},
        // EDIT
		editItem(item) {
			this.item = item;
            
            this.$refs.edit.editItemDialog = true;
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/beat',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "status" : this.$refs.filter.filters.status,
                    "territory_id" : this.$refs.filter.territory,
                    "city_id" : this.$refs.filter.city,
                    "db_id" : this.$refs.filter.distributor,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
                this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/beat/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "status" : this.$refs.filter.filters.status,
                "territory_id" : this.$refs.filter.territory,
                "city_id" : this.$refs.filter.city,
                "db_id" : this.$refs.filter.distributor,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Beat Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
        exportBeatOutletExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingBeatOutletExcel=true;
            }else if(ext == 'csv'){
                this.loadingBeatOutletCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/beat-outlet/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "status" : this.$refs.filter.filters.status,
                "territory_id" : this.$refs.filter.territory,
                "city_id" : this.$refs.filter.city,
                "db_id" : this.$refs.filter.distributor,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Beat + Outlet Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingBeatOutletExcel=false;
                }else if(ext == 'csv'){
                    this.loadingBeatOutletCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingBeatOutletExcel=false;
                }else if(ext == 'csv'){
                    this.loadingBeatOutletCsv=false;
                }
            });
        },
        exportBeatOutletExportCheckbox(ext){

            if(ext == 'xlsx'){
                this.loadingBeatOutletExportCheckbox=true;
            }else if(ext == 'csv'){
                this.loadingBeatOutletExportCheckbox=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/beat-outlet/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "beat_id" : this.itemBeatOutletExport,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Beat + Outlet Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingBeatOutletExportCheckbox=false;
                }else if(ext == 'csv'){
                    this.loadingBeatOutletExportCheckbox=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingBeatOutletExportCheckbox=false;
                }else if(ext == 'csv'){
                    this.loadingBeatOutletExportCheckbox=false;
                }
            });
        },
    }
}
</script>
