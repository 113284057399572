<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="status">Status</label>
                    <Dropdown v-model="filters.status" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Select a Status" :showClear="true"/>
                </div>
            </div>
        </div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Territory</label>
                    <Listbox ref="dterritory" v-model="filters.territory_id" :options="dataDropdownTerritory" :multiple="true" :filter="true" optionLabel="territory_label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownTerritory($event, 'filter')"
                        @change="changeCity()">
                        <template #option="slotProps">
                            <div>{{slotProps.option.territory_label}}</div>
                        </template>
                    </Listbox>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>City</label>
                    <Listbox ref="dcity" v-model="filters.city_id" :options="dataDropdownCity" :multiple="true" :filter="true" optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownCity($event, 'filter')"
                        @change="changeDistributor()">
                        <template #option="slotProps">
                            <div>{{slotProps.option.label}}</div>
                        </template>
                    </Listbox>
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Distributor</label>
                    <Listbox ref="ddistributor" v-model="filters.distributor_id" :options="dataDropdownDistributor" :multiple="true" :filter="true" optionLabel="label"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownDistributor($event, 'filter')">
                        <template #option="slotProps">
                            <div>{{slotProps.option.db_label}}</div>
                        </template>
                    </Listbox>
                </div>
            </div>
        </div>
        <div class="p-fluid">
            <p><b>Territory</b> : {{this.convertMultiSelectTerritoryString(filters.territory_id)}}</p><br>
            <p><b>City</b> : {{this.convertMultiSelectCityString(filters.city_id)}}</p><br>
            <p><b>Distributor</b> : {{this.convertMultiSelectDistributorString(filters.distributor_id)}}</p><br>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
        <Button :loading="loading" label="Clear Filter" icon="pi pi-times" class="p-button-danger mr-2 my-1" @click="clearFilter" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownTerritory: false,
            loadingDropdownCity: false,
            loadingDropdownDistributor: false,

            // dataDropdown
            dataDropdownStatus: [
                {name: 'ACTIVE', code: 'y'},
                {name: 'NON ACTIVE', code: 'n'},
            ],
            dataDropdownTerritory: null,
            dataDropdownCity: null,
            dataDropdownDistributor: null,

            // filter
            filters: {
                status: null,
                territory_id: null,
                city_id: null,
                distributor_id: null,
            },
        }
    },
    mounted() {
        this.searchDropdownTerritory('');
    },
    created(){
    },
    watch: {
    },
    computed:{
        territory(){ 
            return this.convertMultiSelectTerritory(this.filters.territory_id); 
        },
        city(){ 
            return this.convertMultiSelectCity(this.filters.city_id); 
        },
        distributor(){ 
            return this.convertMultiSelectDistributor(this.filters.distributor_id); 
        },
    },
    methods: {
        convertMultiSelectTerritory(data) {
            if(data){
                return data.map((item) => {
                    return item.territory_id;
                });
            }else{
                return [];
            }
        },
        convertMultiSelectTerritoryString(data) {
            if(data){
                return data.map((item) => {
                    return item.territory_label;
                }).join(', ');
            }else{
                return '-';
            }
        },
        convertMultiSelectCity(data) {
            if(data){
                return data.map((item) => {
                    return item.city_id;
                });
            }else{
                return [];
            }
        },
        convertMultiSelectCityString(data) {
            if(data){
                return data.map((item) => {
                    return item.label;
                }).join(', ');
            }else{
                return '-';
            }
        },
        convertMultiSelectDistributor(data) {
            if(data){
                return data.map((item) => {
                    return item.db_id;
                });
            }else{
                return [];
            }
        },
        convertMultiSelectDistributorString(data) {
            if(data){
                return data.map((item) => {
                    return item.db_label;
                }).join(', ');
            }else{
                return '-';
            }
        },
        // DROPDOWN
        searchDropdownTerritory(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dterritory.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownTerritory = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-territory',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "filter") {
                        this.dataDropdownTerritory = res.data.data;
                        this.loadingDropdownTerritory = false;
                    } else if (purpose == null) {
                        this.dataDropdownTerritory = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }, 250);
        },
        changeCity() {
            console.log(this.filters.territory_id);
            this.filters.city_id = null;
            this.dataDropdownCity = null;
            this.searchDropdownCity('');

            this.changeDistributor();
        },
        searchDropdownCity(event, purpose, valueEdit) {
            setTimeout(() => {

                var $x = this.convertMultiSelectTerritory(this.filters.territory_id);

                if($x.length > 0){
                    if (valueEdit) {
                        this.$refs.dcity.filterValue = valueEdit;
                    }

                    if (purpose == "filter") {
                        this.loadingDropdownCity = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-city6',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "territory_id" : $x
                        }
                    })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownCity = res.data.data;
                            this.loadingDropdownCity = false;
                        } else if (purpose == null) {
                            this.dataDropdownCity = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                }

            }, 250);
        },
        changeDistributor() {
            this.filters.distributor_id = null;
            this.dataDropdownDistributor = null;
            this.searchDropdownDistributor('');
        },
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {

                var $x = this.convertMultiSelectCity(this.filters.city_id);

                if($x.length > 0){

                    if (valueEdit) {
                        this.$refs.ddistributor.filterValue = valueEdit;
                    }

                    if (purpose == "filter") {
                        this.loadingDropdownDistributor = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "city_id": $x
                        }
                    })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownDistributor = res.data.data;
                            this.loadingDropdownDistributor = false;
                        } else if (purpose == null) {
                            this.dataDropdownDistributor = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }

            }, 250);
        },
        getDataTable(){
            this.$emit('submit');
        },
        clearFilter(){
            this.filters.status = null;
            this.filters.territory_id = null;

            this.filters.city_id = null;
            this.dataDropdownCity = null;

            this.filters.distributor_id = null;
            this.dataDropdownDistributor = null;

            this.$emit('submit');
        }
    }
}
</script>

