<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Beat</label>
                <InputText v-model="forms.beat_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.beat_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.beat_name" >{{ errorAdd.beat_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Distributor</label>
                    <Dropdown dataKey="label" v-model="forms.db_id" :loading="loadingDropdownDistributor"
                        :options="dataDropdownDistributor" :class="{ 'p-invalid': errorAdd.db_id }"
                        optionLabel="label" optionValue="db_id" placeholder="Select Distributor"
                        :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'add')" @change="changeCity()"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.db_id">{{
                        errorAdd.db_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>City</label>
                    <Dropdown dataKey="city_id" v-model="forms.city_id" :loading="loadingDropdownCity"
                        :options="dataDropdownCity" :class="{ 'p-invalid': errorAdd.city_id }"
                        optionLabel="label" optionValue="city_id" placeholder="Select City"
                        :filter="true" :showClear="true" @filter="searchDropdownCity($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.city_id">{{
                        errorAdd.city_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownDistributor: false,
            loadingDropdownCity: false,

            // dataDropdown
            dataDropdownDistributor: null,
            dataDropdownCity: null,

            // addNew
            forms: {
                beat_name: null,
                db_id: null,
                city_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownDistributor('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownDistributor(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.ddist.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownDistributor = res.data.data;
                        this.loadingDropdownDistributor = false;
                    }else if(purpose == null){
                        this.dataDropdownDistributor = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeCity() {
            this.forms.city_id = null;
            this.dataDropdownCity = null;
            this.searchDropdownCity('');
        },
        searchDropdownCity(event, purpose, valueEdit){
            setTimeout(() => {
                if(this.forms.db_id){
                    
                    if(valueEdit){
                        this.$refs.dcity.filterValue = valueEdit;
                    }

                    if(purpose == "add"){
                        this.loadingDropdownCity = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-city4',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "db_id" : this.forms.db_id
                        }
                    })
                    .then(res => {

                        if(purpose == "add"){
                            this.dataDropdownCity = res.data.data;
                            this.loadingDropdownCity = false;
                        }else if(purpose == null){
                            this.dataDropdownCity = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                }
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/beat/create',
                data: {
                    "beat_name": this.forms.beat_name,
                    "db_id": this.forms.db_id,
                    "city_id": this.forms.city_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.beat_name = null;
            this.forms.db_id = null;
            this.forms.city_id = null;

            this.dataDropdownCity = null;
        },
    }
}
</script>